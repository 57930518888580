const theme = {
  breakpoints: [
    '40em', '52em', '64em', '90em'
  ],
  colors: {
    black: '#000',
    white: '#fff',
    gray: '#BDBDBD',

    mysore: '#A51E78',
    led: '#64237F',
    beginner: '#872978',
    technique: '#5D49A6',
    other: '#571B62',
    red: '#D7041F',

    darken: [
      'rgba(0,0,0,.125)',
      'rgba(0,0,0,.25)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.75)',
    ],

    lighten: [
      'rgba(255,255,255,.125)',
      'rgba(255,255,255,.25)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.75)',
    ]
  },
  fontSizes: [
   12,
   14,
   18,
   20,
   24,
   32,
   48,
   64,
   72,
   96
 ],
 space: [
   0,
   4,
   8,
   16,
   24,
   32,
   64,
   128,
 ],
}

export default theme
