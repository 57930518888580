import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Flex } from 'rebass'
import { themeGet } from 'styled-system'

const NavLink = styled(Link)`
  display: block;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: ${props => props.position === 'footer' ? themeGet('space.1') : themeGet('space.2')}px;;
  text-decoration: none;
  color: black;
  transition: all .25s ease;
  font-size: ${props => props.position === 'footer' ? themeGet('fontSizes.2') : themeGet('fontSizes.4')}px;

  &::after {
    position: absolute;
    left: 0;
    bottom: -4px;
    content: "";
    height: 1px;
    width: 0;
    background-image: linear-gradient(to right, #571B62, #A51E78);
    background-color: #571B62;
    transition: width .25s ease;
  }

  @media (min-width: ${themeGet('breakpoints.1')}) {
    padding: 0;
    font-size: ${themeGet('fontSizes.2')}px;

    &:hover {
      color: #571B62;

      &::after {
        width: 100%;
      }
    }
  }
`

const Navigation = props => (
  <Flex flexDirection={['column',null,'row']} alignItems='center'>
    <NavLink position={props.position} to='/viikkotunnit'>Viikkotunnit</NavLink>
    <NavLink position={props.position} to='/kurssit'>Kurssit</NavLink>
    <NavLink position={props.position} to='/yhteystiedot'>Yhteystiedot</NavLink>
  </Flex>
)

export default Navigation
