import React from 'react'
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl'

let MapBox = null;

class Map extends React.Component {
  UNSAFE_componentWillMount() {
    if (typeof window !== `undefined`) {
      MapBox = ReactMapboxGl({
        accessToken: "pk.eyJ1IjoianJvaW5lIiwiYSI6ImNqa3NpdnN0ZTQ1YjEzcGt4dTM5bXJyZXoifQ.yqbz1P3Gdy_EObqGjJ3geA",
        scrollZoom: false,
        attributionControl: false,
        logoPosition: 'top-left'
      });
    }
  }

  render() {
    if (typeof window === `undefined`) { return null; }

    return (
      <MapBox
        style="mapbox://styles/jroine/cjkw3fjno14ve2rqtq315qlm4"
        center={[25.709,66.494]}
        zoom={[16]}
        containerStyle={{
          height: "100%",
          width: "100%"
        }}>
          <Layer
            type="symbol"
            id="marker"
            layout={{
              "icon-image": "minimo-grey_poi-2"
            }}>
            <Feature coordinates={[25.709,66.494]}/>
          </Layer>
          <ZoomControl position="bottom-right" />
      </MapBox>
    )
  }
}

export default Map
