import React from 'react'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Box } from 'rebass'

const InstagramFeed = props => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent(limit: 3, sort: { fields: timestamp, order: DESC }) {
        edges {
          node {
            caption
            media_url
            localImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            id
          }
        }
      }
    }
  `)

  return (
    <Flex flexDirection={['column', 'row']}>
      {data.allInstagramContent.edges.map(({ node }) => (
        <Box
          as="a"
          href={`https://www.instagram.com/p/${node.id}`}
          color="white"
          px={[2, 3]}
          mb={[6, 0]}
          width={[1, 1 / 3]}
        >
          <Img
            fluid={{
              ...node.localImage.childImageSharp.fluid,
              aspectRatio: 1,
            }}
            alt={node.caption}
            key={node.id}
            style={{
              borderRadius: '8px',
            }}
          />
          <Box fontSize={1} mt={3}>
            {node.caption}
          </Box>
        </Box>
      ))}
    </Flex>
  )
}

export default InstagramFeed
