import { createGlobalStyle } from 'styled-components'
import { themeGet } from 'styled-system'
import '../fonts/avenir-next.css'

const GlobalStyle = createGlobalStyle`
  * { box-sizing: border-box }

  body {
    margin: 0;
    font-family: "AvenirNextLTPro-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5 {
    font-family: "AvenirNextLTPro-Demi", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    font-style: normal;
  }

  strong, b {
    font-family: "AvenirNextLTPro-Demi", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    font-style: normal;
  }

  a {
    color: #A51E78;
    text-decoration: none;
  }

  hr {
    border-top: 0;
    border-bottom: 1px solid ${themeGet('colors.gray')};
  }

  blockquote {
    margin-left: 0;
    margin-top: 32px;
    font-family: "AvenirNextLTPro-Demi";
    color: #A51E78;
    p {
      margin: 0;
    }
  }
`

export default GlobalStyle
