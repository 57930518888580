import { Box } from 'rebass'
import styled from 'styled-components'
import { maxWidth } from 'styled-system'

const Container = styled(Box)`
  ${maxWidth}
`

Container.propTypes = {
  ...maxWidth.propTypes,
}

Container.defaultProps = {
  maxWidth: '90em',
  px: [3,4,5],
  mx: 'auto'
}

export default Container
