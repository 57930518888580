import React from 'react'
import { Flex, Box, Text } from 'rebass'
import { Relative } from '@rebass/position'
import { Hide } from '@rebass/hide'
import { Link } from 'gatsby'
import Logo from './logo'
import Navigation from './navigation'
import Social from './social'
import Map from '../components/map'
import 'mapbox-gl/dist/mapbox-gl.css'
import styled from 'styled-components'

const SkewedBox = styled(Box)`
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before,
  &::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="white" points="100,100 100,0 0,0"/></svg>');
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    display: block;
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 4rem;
  }

  &::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="white" points="100,100 0,0 0,100"/></svg>');
    top: auto;
    bottom: 0;
  }

  @media (min-width: 40em) {
    &::before,
    &::after {
      height: 8rem;
    }
  }
`

const FooterLink = styled(Link)`
  color: black;
`

const Footer = () => (
  <Relative zIndex="0">
    <SkewedBox>
      <Box style={{ height: '50vh' }}>
        <Map />
      </Box>
    </SkewedBox>

    <Flex flexDirection="column" alignItems="center" px={3} mb={[4, null, 5]}>
      <Box mb={3}>
        <Logo />
      </Box>

      <Navigation position="footer" />

      <Box mb={3} mt={4}>
        <Social />
      </Box>

      <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
        <Text fontSize={1} textAlign="center" lineHeight={1.5} mb={[2, 0]}>
          &copy; 2018 Astangajooga Lappi ry
        </Text>
        <Hide xsmall>
          <Text px={2} lineHeight={1.5}>
            <strong>&middot;</strong>
          </Text>
        </Hide>
        <Text fontSize={1} textAlign="center" lineHeight={1.5} mb={[2, 0]}>
          Varastotie 2, 96100 Rovaniemi (keltainen talo, 2 krs.)
        </Text>
        <Hide xsmall>
          <Text px={2} lineHeight={1.5}>
            <strong>&middot;</strong>
          </Text>
        </Hide>
        <Text fontSize={1} textAlign="center" lineHeight={1.5}>
          <FooterLink to="/tietoturvaseloste">Tietoturvaseloste</FooterLink>
        </Text>
      </Flex>
    </Flex>
  </Relative>
)

export default Footer
