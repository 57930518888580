import React from 'react'
import { Flex, Box } from 'rebass'

const Social = () => (
  <Flex alignItems='center'>
    <Box px={2}>
      <a href='https://facebook.com/astangajoogalappi'>
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h12v-9h-3v-4h3V8.413c0-3.1 1.893-4.788 4.659-4.788 1.324 0 2.463.098 2.794.143v3.24h-1.917c-1.504 0-1.796.715-1.796 1.763V11h4.44l-1 4h-3.44v9H23a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" fill="#000" fillRule="evenodd"/>
        </svg>
      </a>
    </Box>
    <Box px={2}>
      <a href='https://instagram.com/astangajoogalappi'>
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.1 0H2.9C1.3 0 0 1.3 0 2.8v18.3C0 22.7 1.3 24 2.9 24h18.3c1.6 0 2.9-1.3 2.9-2.8V2.8C24 1.3 22.7 0 21.1 0zM18 3h2c.6 0 1 .4 1 1v2c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1V4c0-.6.4-1 1-1zm-6 4.4c2.5 0 4.6 2.1 4.6 4.6 0 2.5-2.1 4.6-4.6 4.6-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6zM20 21H4c-.6 0-1-.4-1-1V10h2c-.3 1.1-.4 2.4-.1 3.6.6 2.8 2.9 5 5.7 5.5 4.7.9 8.8-2.6 8.8-7.1 0-.7-.2-1.4-.3-2h2v10c-.1.6-.5 1-1.1 1z" fill="#000" fillRule="evenodd"/>
        </svg>
      </a>
    </Box>
  </Flex>
)

export default Social
