import React from 'react'
import { Flex, Box } from 'rebass'
import { Hide } from '@rebass/hide'
import Logo from './logo'
import Navigation from './navigation'
import Social from './social'
import styled from 'styled-components'

const Fixed = styled(Box)`
  position: fixed;
`

const NavMenuLink = styled.a`
  display: flex;
  padding: 8px;
`

const MobileNavContainer = styled(Fixed)`
  display: none;
  z-index: 999;
  top: 0; right: 0; bottom: 0; left: 0;

  &.true {
    display: block;
  }
`

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      clicked: []
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index,e) {
    let clicked = this.state.clicked;
    clicked[index] = !clicked[index]
    this.setState({clicked: clicked});
  }

  render() {
    return (
      <Flex
        p={[3,null,4]}
        justifyContent='space-between'
        alignItems='center'
      >
        <Logo />

        <Hide xsmall small>
          <Navigation />
        </Hide>

        <Hide medium large xlarge>
          <NavMenuLink href='#' onClick={this.handleClick.bind(this,0)}>
            <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd">
                <path d="M15 6H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1" fill="#000"/>
                <path d="M15 0H1C.4 0 0 .4 0 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1M15 12H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1" fill="#000"/>
              </g>
            </svg>
          </NavMenuLink>

          <MobileNavContainer top='0' right='0' bottom='0' left='0' bg='#fff' className={this.state.clicked[0]}>
            <Flex flexDirection='column' justifyContent='center' style={{ minHeight: '100%' }}>
              <Flex alignItems='center' justifyContent='space-between' p={[3,null,4]}>
                <Logo />
                <NavMenuLink href='#' onClick={this.handleClick.bind(this,0)}>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.728.3c-.4-.4-1-.4-1.4 0l-5.3 5.3-5.3-5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l5.3 5.3-5.3 5.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l5.3-5.3 5.3 5.3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L8.428 7l5.3-5.3c.4-.4.4-1 0-1.4" fill="#444" fillRule="evenodd"/>
                  </svg>
                </NavMenuLink>
              </Flex>

              <Flex alignItems='center' justifyContent='center' flex='auto'>
                <Navigation />
              </Flex>

              <Flex justifyContent='center' py={2}>
                <Social />
              </Flex>
            </Flex>
          </MobileNavContainer>
        </Hide>
      </Flex>
    )
  }
}

export default Header
