import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { withFormik, Field, FieldArray } from 'formik'
import Yup from 'yup'
import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass'
import { themeGet, space } from 'styled-system'
import classNames from 'classnames'
import { format } from 'date-fns'
import _ from 'lodash'
var fiLocale = require('date-fns/locale/fi')

const Input = styled.input`
  display: inline-block;
  width: 100%;
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  border: 2px solid ${themeGet('colors.gray')};
  border-radius: 8px;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.2')}px;
  line-height: 1.5;
  vertical-align: middle;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  ${space} &:focus {
    outline: none;
  }
`

const Form = styled.form`
  input {
    &:focus {
      border-color: ${themeGet('colors.mysore')};
    }

    &.is-invalid {
      border-color: ${themeGet('colors.red')};
    }
  }
`

const SubmitButton = styled.button`
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  background-color: ${themeGet('colors.mysore')};
  border-radius: 8px;
  font-weight: normal;
  font-family: 'AvenirNextLTPro-Demi', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: ${themeGet('fontSizes.2')}px;
  line-height: 1;
  cursor: pointer;
  border: 0;
  color: #fff;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: inset 0 0 0 999px ${themeGet('colors.darken.0')};
  }
`

const InputFeedback = ({ error }) =>
  error ? (
    <Text fontSize={1} color="red" mt={1} className="invalid-feedback">
      {error}
    </Text>
  ) : null

const ClassItem = styled(Box)`
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid ${themeGet('colors.gray')};

  &:first-child {
    label {
      border-radius: 6px 6px 0 0;
    }
  }

  &:last-child {
    border-bottom: none;

    label {
      border-radius: 0 0 6px 6px;
    }
  }

  &:first-child:last-child label {
    border-radius: 6px;
  }

  label {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: background-color 0.15s ease;

    &:hover {
      background-color: ${themeGet('colors.darken.0')};
    }
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;

    ~ label::before {
      position: absolute;
      top: 18px;
      left: 12px;
      display: none;
      width: 1rem;
      height: 1rem;
      opacity: 0;
      content: '';
      background: url('data:image/svg+xml,<svg width="16" height="13" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.6 8L2.4 4.8 0 7.2l5.6 5.6L16 2.4 13.6 0z" fill="white" fill-rule="evenodd"/></svg>');
      background-repeat: no-repeat;
      transition: opacity 5s ease;
    }
  }

  input[type='radio']:active ~ label,
  input[type='radio']:checked ~ label {
    background-color: ${themeGet('colors.mysore')};
    color: #fff;

    &::before {
      display: block;
      opacity: 1;
    }

    .class-title {
      transition: all 0.1s ease;
      padding-left: 20px;
    }
  }
`

function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <Flex as="label" mb={3}>
          <input
            {...field}
            type="checkbox"
            checked={field.value}
            onChange={field.onChange}
            style={{ flex: 'none' }}
          />
          <Text ml={2}>{props.value}</Text>
        </Flex>
      )}
    </Field>
  )
}

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  eventClass,
  ...props
}) => {
  let price = eventClass.discountedPrice
    ? eventClass.price + ' / ' + eventClass.discountedPrice
    : eventClass.price

  return (
    <ClassItem>
      <input
        type="radio"
        name={name}
        id={eventClass.contentful_id}
        value={eventClass.contentful_id}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames('radio-button')}
        {...props}
      />
      <label htmlFor={id}>
        <Flex p={3}>
          <Box flex="auto" className="class-title">
            <Text lineHeight={1}>
              <strong>{eventClass.name}</strong>
            </Text>
          </Box>
          <Box flex="none" width={1 / 4}>
            <Text textAlign="right" lineHeight={1}>
              {price} &euro;
            </Text>
          </Box>
          <Box flex="none" width={1 / 4}>
            <Text textAlign="right" lineHeight={1}>
              {eventClass.referenceNumber}
            </Text>
          </Box>
        </Flex>
      </label>
    </ClassItem>
  )
}

const RadioGroup = styled(Box)`
  border-radius: 8px;
  box-shadow: inset 0 0 0 2px #bdbdbd;

  &.is-error {
    box-shadow: inset 0 0 0 2px ${themeGet('colors.red')};
  }
`

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  className,
  children,
}) => {
  const classes = classNames(
    'input-field',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched,
    },
    className
  )

  return (
    <Box>
      <RadioGroup className={classes}>{children}</RadioGroup>
      {touched && <InputFeedback error={error} />}
    </Box>
  )
}
/*
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
*/
const EventRegistrationForm = props => {
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    event,
    disabled,
  } = props
  //console.log(disabled)

  const [showOk, setShowOk] = useState(false)

  return showOk ? (
    <>
      <Text textAlign="center" fontSize={6}>
        <span role="img" aria-label="Kiitos">
          🙏
        </span>
      </Text>
      <Text textAlign="center" fontSize={4}>
        <strong>Kiitos ilmoittautumisesta ja tervetuloa joogaamaan!</strong>
      </Text>
    </>
  ) : (
    <Form
      id="registrationForm"
      name="ilmoittautuminen"
      method="post"
      action="/kiitos"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <p hidden>
        <label>
          Älä täytä: <input name="bot-field" />
        </label>
      </p>
      <fieldset
        disabled={disabled}
        style={{
          padding: 0,
          border: 0,
          opacity: disabled ? 0.5 : 1,
          margin: 0,
        }}
      >
        {event.classes && (
          <Box my={4}>
            <Flex px={3} mb={1}>
              <Box flex="auto" />
              <Box flex="none" width={1 / 4}>
                <Text fontSize={1} textAlign="right">
                  Hinta
                </Text>
              </Box>
              <Box flex="none" width={1 / 4}>
                <Text fontSize={1} textAlign="right">
                  Viite
                </Text>
              </Box>
            </Flex>

            <RadioButtonGroup
              id="eventClass"
              value={values.eventClass}
              error={errors.eventClass}
              touched={touched.eventClass}
            >
              <Flex
                p={3}
                bg="#f5f5f5"
                style={{
                  borderRadius: '8px 8px 0 0',
                  boxShadow: 'inset 0 0 0 2px #bdbdbd',
                }}
              >
                <Box flex="auto" className="class-title">
                  <Text lineHeight={1}>
                    <strong>
                      Jäsenmaksu{' '}
                      <Text color="mysore" as="span">
                        *
                      </Text>
                    </strong>
                  </Text>
                </Box>
                <Box flex="none" width={1 / 4}>
                  <Text textAlign="right" lineHeight={1}>
                    15 &euro;
                  </Text>
                </Box>
                <Box flex="none" width={1 / 4}>
                  <Text textAlign="right" lineHeight={1}>
                    1232
                  </Text>
                </Box>
              </Flex>

              {event.classes.map(eventClass => (
                <Field
                  component={RadioButton}
                  name="eventClass"
                  disabled={disabled}
                  id={eventClass.contentful_id}
                  key={eventClass.contentful_id}
                  eventClass={eventClass}
                />
              ))}
            </RadioButtonGroup>
          </Box>
        )}

        {!!event.calendar && (
          <>
            <FieldArray
              name="eventCalendar"
              id="eventCalendar"
              error={errors.eventCalendar}
              touched={touched.eventCalendar}
              render={arrayHelpers => (
                <Box mb={4}>
                  <Text mb={2}>
                    <strong>Ilmoittaudu tunneille</strong>
                  </Text>
                  {touched.eventCalendar && (
                    <InputFeedback error={errors.eventCalendar} />
                  )}
                  {event.calendar.map(item => (
                    <Box key={item.contentful_id} py={1}>
                      <Flex as="label" justifyContent="space-between">
                        <input
                          name="eventCalendar"
                          type="checkbox"
                          value={item.contentful_id}
                          checked={values.eventCalendar.includes(
                            item.contentful_id
                          )}
                          onChange={e => {
                            if (e.target.checked)
                              arrayHelpers.push(item.contentful_id)
                            else {
                              const idx = values.eventCalendar.indexOf(
                                item.contentful_id
                              )
                              arrayHelpers.remove(idx)
                            }
                          }}
                          style={{ flex: 'none' }}
                        />{' '}
                        <Box flex="auto" ml={2}>
                          {_.capitalize(
                            format(item.startTime, 'dddd DD.MM. [klo] HH.mm', {
                              locale: fiLocale,
                            })
                          )}
                        </Box>
                        <Box>
                          {item.participants ? item.participants.length : 0} /{' '}
                          {item.maxParticipants}
                        </Box>
                      </Flex>
                    </Box>
                  ))}
                </Box>
              )}
            />
          </>
        )}

        {event.eventType === 'Weekend Course' && event.additionalInformation && (
          <Text
            fontSize={1}
            pb={3}
            dangerouslySetInnerHTML={{
              __html: event.additionalInformation.childMarkdownRemark.html,
            }}
          />
        )}

        <Flex my={2} flexWrap="wrap">
          <Box width={1}>
            <label>Nimi</label>
          </Box>
          <Box pr={2} width={1 / 2}>
            <Input
              className={errors.firstName && touched.firstName && 'is-invalid'}
              name="firstName"
              values={values.firstName}
              placeholder="Etunimi"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.firstName && touched.firstName && (
              <Text
                mt={1}
                fontSize={1}
                color="red"
                className="invalid-feedback"
              >
                {errors.firstName}
              </Text>
            )}
          </Box>
          <Box pl={2} width={1 / 2}>
            <Input
              className={errors.lastName && touched.lastName && 'is-invalid'}
              name="lastName"
              values={values.lastName}
              placeholder="Sukunimi"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.lastName && touched.lastName && (
              <Text
                mt={1}
                fontSize={1}
                color="red"
                className="invalid-feedback"
              >
                {errors.lastName}
              </Text>
            )}
          </Box>
        </Flex>
        <Box my={2}>
          <label>Puhelinnumero</label>
          <Input
            className={errors.phone && touched.phone && 'is-invalid'}
            name="phone"
            placeholder="Puhelinnumero"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.phone && touched.phone && (
            <Text mt={1} fontSize={1} color="red" className="invalid-feedback">
              {errors.phone}
            </Text>
          )}
        </Box>
        <Box my={2}>
          <label>Sähköpostiosoite</label>
          <Input
            className={errors.email && touched.email && 'is-invalid'}
            name="email"
            placeholder="Sähköpostiosoite"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && (
            <Text mt={1} fontSize={1} color="red" className="invalid-feedback">
              {errors.email}
            </Text>
          )}
        </Box>
        <Box my={2}>
          <label>Kotipaikka</label>
          <Input
            className={errors.hometown && touched.hometown && 'is-invalid'}
            name="hometown"
            placeholder="Kotipaikka"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.hometown && touched.hometown && (
            <Text mt={1} fontSize={1} color="red" className="invalid-feedback">
              {errors.hometown}
            </Text>
          )}
        </Box>

        <Box my={3}>
          <Checkbox
            name="marketing"
            value="Haluan vastaanottaa joogayhdistykseltä sähköpostia liittyen tuleviin kursseihin ja aikatauluihin."
          />
          <Checkbox
            name="membership"
            value={<strong>Ilmoittaudun myös jäseneksi.</strong>}
          />
          <Text fontSize={1} mb={3}>
            <Text color="mysore" as="strong">
              *
            </Text>{' '}
            <strong>
              Tunneille osallistuminen edellyttää joogayhdistyksen jäsenyyttä.
            </strong>{' '}
            Jos et ole vielä rekisteröitynyt jäseneksi, sinun tulee
            rekisteröityä jäseneksi samalla kun ilmoittaudut tunnille. Jäsenyys
            on voimassa kalenterivuoden ajan (tammikuusta joulukuuhun).
            Maksathan jäsenmaksun ja kurssimaksun erikseen niille osoitetuilla
            viitenumeroilla.
          </Text>
        </Box>

        <input type="hidden" name="form-name" value="ilmoittautuminen" />
        <input type="hidden" name="event" value={event.name} />
        <input type="hidden" name="date" value={event.dateRange} />
        {event.additionalInformation && (
          <input
            type="hidden"
            name="additionalInformation"
            value={event.additionalInformation.childMarkdownRemark.html}
          />
        )}
        <input type="hidden" name="price" value="" />
        <input type="hidden" name="referenceNumber" value="" />
      </fieldset>
      <SubmitButton type="submit" disabled={disabled || isSubmitting}>
        {isSubmitting ? 'Hetkinen...' : 'Ilmoittaudu'}
      </SubmitButton>
    </Form>
  )
}

const RegistrationForm = withFormik({
  mapPropsToValues: ({ event }) => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    hometown: '',
    eventClass: '',
    eventCalendar: [],
    event: event.name,
    membership: false,
    marketing: false,
    date: event.dateRange,
    'form-name': 'ilmoittautuminen',
  }),

  validationSchema: Yup.object().shape({
    eventClass: Yup.string().required('Valitse kurssi.'),
    firstName: Yup.string().required('Etunimi on pakollinen tieto.'),
    lastName: Yup.string().required('Sukunimi on pakollinen tieto.'),
    phone: Yup.string().required('Puhelinnumero on pakollinen tieto.'),
    email: Yup.string()
      .email('Virheellinen sähköpostiosoite.')
      .required('Sähköpostiosoite on pakollinen tieto.'),
    hometown: Yup.string().required('Kotipaikka on pakollinen tieto.'),
  }),

  handleSubmit: async (values, { setSubmitting }) => {
    /*
    var courseInfo = values.kurssi.split(';')
    values.kurssi = courseInfo[0]
    values.price = courseInfo[1]
    values.referenceNumber = courseInfo[2]
*/

    try {
      const response = await fetch('/.netlify/functions/event', {
        method: 'POST',
        body: JSON.stringify(values),
      })

      if (!response.ok) {
        //not 200 response
        return
      }
      const body = response.json()
      //console.log(body)

      navigate('/kiitos/', {
        state: { body },
        replace: true,
      })
      setSubmitting(false)
      //all OK
    } catch (e) {
      //error
      console.log('error:', e)
    }
  },
  displayName: 'RegistrationForm', // helps with React DevTools
})(EventRegistrationForm)

export default RegistrationForm
