import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Text } from 'rebass'

const LogoLink = styled(Link)`
  text-decoration: none;
  color: black;
  background: -webkit-gradient(
    linear,
    left center, right center,
    from(#571B62),
    to(#A51E78)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Logo = ({ siteTitle }) => (
  <Text fontSize={[2,null,3]}>
    <LogoLink to='/'>
      <strong>Astangajooga Lappi ry</strong>
    </LogoLink>
  </Text>
)

export default Logo
