import Link from 'gatsby-link'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const Action = styled(Link)`
  display: inline-block;
  width: auto;
  font-family: "AvenirNextLTPro-Demi", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-style: normal;
  border-radius: 8px;
  vertical-align: 1em;
  padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  background: ${themeGet('colors.mysore')};
  color: white;
  text-decoration: none;
  font-size: ${themeGet('fontSizes.1')}px;

  @media (min-width: ${themeGet('breakpoints.0')}) {
    font-size: ${themeGet('fontSizes.2')}px;
  }
`

export default Action
