import React from 'react'
import { Card } from 'rebass'

const Embed = props =>
  <Card
    {...props}
    width={1}
    style={{
      height: 0,
      paddingBottom: (props.ratio ? props.ratio : 9 / 16) * 100 + '%',
      position: 'relative',
      overflow: 'hidden',
      '& > iframe': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        border: 0
      }
    }}
  />

export default Embed
