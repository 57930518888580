import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Text, Flex } from 'rebass'
import Container from '../components/container'
import { Relative } from '@rebass/position'
import Img from 'gatsby-image'

const HeroImage = styled(Img)`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 320px;
  max-height: 60vh;
  margin-left: auto;
  margin-right: auto;

  & > img,
  & > picture > img {
    -webkit-filter: grayscale(1);
    filter: gray;
    filter: grayscale(1) contrast(1.125);
  }

  &::before {
    mix-blend-mode: dark-light;
    background-blend-mode: multiply;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(135deg, #64237F 0%, #6D1866 25%, #79094C 75%, #90065D 100%);
    opacity: .75;
  }

  &::after {
    opacity: .5;
  }
`

const SkewedBox = styled(Flex)`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow: hidden;
  z-index: 1;

  &::after {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="white" points="100,100 0,0 0,100"/></svg>');
    background-repeat: no-repeat;
    background-size: 100vw 100%;
    display: block;
    content: "";
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4rem;
  }

  @media (min-width: 40em) {
    &::before,
    &::after {
      height: 8rem;
    }
  }
`

const Hero = ({ title, excerpt, image }) => (
  <StaticQuery
    query={graphql`
      query HeroQuery {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Relative>
        <HeroImage
          fluid={image ?
            image.fluid : data.hero.childImageSharp.fluid
          }
        />
        <SkewedBox alignItems='center' justifyContent='center'>
          <Container pt={[6,7]} pb={[7,'12rem']} px={[3,4]} maxWidth='64em'>
            <Heading
              as='h1'
              fontSize={[4,5,6,7]}
              fontWeight='normal'
              textAlign='center'
              m={0}
              color='white'
            >{title}</Heading>
            {excerpt &&
              <Container maxWidth={[null,null,'40em','48em']} px={0} mt={3}>
                <Text color='white' fontSize={[2,3,null,4]} textAlign='center' dangerouslySetInnerHTML={{ __html: excerpt }}></Text>
              </Container>
            }
          </Container>
        </SkewedBox>
      </Relative>
    )}
  />
)

export default Hero
