import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import config from '../utils/siteConfig'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, data }) => (
  <div>
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta charSet="UTF-8" />
      <meta httpEquiv="Content-type" content="text/html; charset=UTF-8" />
      <meta name='description' content={config.siteDescription} />
      <meta property="og:title" content={config.siteTitle} />
      <meta property="og:locale" content="fi_FI" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={config.siteTitle} />
      <meta property="og:url" content={config.siteUrl}/>
    </Helmet>

    <GlobalStyle />

    <ThemeProvider theme={theme}>
      <div>
        <Header />
        {children}
        <Footer />
      </div>
    </ThemeProvider>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
